import '../scss/global.scss';
import '../scss/_login-admin.scss';
import '../scss/_push-fix.scss';

import 'jquery';
import 'jquery-validation';
import '../libs/pagination/pagination.min.js'
import '../libs/datepicker/jquery-ui.min.js'
// import 'bootstrap';
import 'bootstrap-tooltip';
import 'select2';
import 'select2/dist/css/select2.css';
import 'bootstrap-material-design';
import 'jquery.nicescroll';

import 'jquery-hoverintent';

import 'jquery.fancybox/source/jquery.fancybox.css';
import 'jquery.fancybox';

import 'slick-carousel';

(function () {
    $("body").bootstrapMaterialDesign();

    $(".block-search").on("click", function () {
        $(".input_search").addClass("active");

        setTimeout(function () {
            $("#search_input").focus();
        }, 300);
    });

    $("#search_input").on("focusout", function () {
        $(".input_search").removeClass("active");
    });

    $('[data-url]').css('cursor', 'pointer');
    $('[data-url]').on('click', function () {
        window.location = $(this).data('url');
    });

    let $scroll = $("#scroll-up");

    $scroll.on("click", function () {
        $("html, body").animate({scrollTop: 0}, 800);
        return false;
    });

    function showHideScrollTop() {
        if ($(window).scrollTop() > $(window).height() / 2) {
            $scroll.fadeIn(300);
        } else {
            $scroll.fadeOut(300);
        }
    }

    showHideScrollTop();
    $(window).scroll(function () {
        showHideScrollTop();
    });

    /*----- Hide error message on modal hide -----*/
    $('#active_account_modal').on('hidden.bs.modal', function (e) {
        $('.text-center.activation-failure-error-message-not-found.error-message').addClass('d-none');
    });

    $('#active_account_modal_demandeur').on('hidden.bs.modal', function (e) {
        $('.text-center.activation-failure-error-message-not-found.error-message').addClass('d-none');
    });


    function mobile_menu() {
        if ($(window).width() < 992) {
            if ($("#menu").length > 0) {
                if ($("#mobile-menu").length < 1) {
                    $("#menu").clone().attr({
                        id: "mobile-menu",
                        class: ""
                    }).insertAfter("#header");

                    $("#mobile-menu .megamenu > a").on("click", function (e) {
                        e.preventDefault();
                        $(this).toggleClass("open").next("div").slideToggle(300);
                    });

                    $("#mobile-menu .dropdown > a").on("click", function (e) {
                        e.preventDefault();
                        $(this).toggleClass("open").next("ul").slideToggle(300);
                    });

                    $("#mobile-menu .cart > a").on("click", function (e) {
                        e.preventDefault();
                        $(this).toggleClass("open").next("div").slideToggle(300);
                    });
                }
            }
        } else {
            $("#mobile-menu").hide();
        }
    }

    mobile_menu();
    $(window).resize(function () {
        mobile_menu();
    });

    $("#mobile-menu-button").on("click", function (e) {
        e.preventDefault();
        $("#mobile-menu").slideToggle(300);
    });

    // >>> RGPD
    //========== COOKIE VALIDITY LENGTH ==========
    var validity = 180;
    //============================================
    var cookie_banner = $('.cookie_banner');
    var cookie_modal = $('#cookie_modal');
    var rgpd_close_btn_modal = $('.close-button');
    var rgpd_accept = $('.rgpd_accept');
    var rgpd_refuse = $('.rgpd_refuse');
    var rgpd_event = $('.rgpd_event');
    var rgpd_validate = $('.rgpd_validate');
    // id to put on link in ckeditor (builder page).
    var rgpd_modify = $('.rgpd_modify');
    var rgpd_modal = $('#rgpd_modal');
    var rgpd_functional = $('#rgpd_option_functional');
    var _token = $('#token_rgpd');

    rgpd_accept.on('click', function() {
        cookie_modal.modal('hide');
        setRgpd('accept');
    });

    rgpd_refuse.on('click', function() {
        cookie_modal.modal('hide');
        setRgpd('refuse');
    });

    rgpd_validate.on('click', function() {
        setRgpd('validate');
    });

    rgpd_modal.on('show.bs.modal', function(){
        getCookie('rgpd_functional') == 1 ? rgpd_functional.prop('checked', true) : rgpd_functional.prop('checked', false);
    });

    rgpd_modify.on('click', function() {
        cookie_modal.modal('hide');
        rgpd_modal.modal({backdrop: 'static', keyboard: false});
    });

    rgpd_close_btn_modal.on('click', function() {
        rgpd_modal.modal('hide');
    });

    // Checks and removes old rgpd cookie
    var rgpd_cookie = getCookie('rgpd');
    if (rgpd_cookie != null) {
        document.cookie = 'rgpd=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    // Checks new rgpd cookie and show modal if null
    var rgpd_cookieV2 = getCookie('rgpdV2');
    if(rgpd_cookieV2 == null){
        cookie_modal.modal({backdrop: 'static', keyboard: false});
    }

    function setRgpd(action) {
        var consent = [];
        if( rgpd_event.length > 0 ){
            rgpd_event.each(function(index) {
                var cookie_name = $(this).data('cname');
                var cvalue;
                switch (action) {
                    case 'accept':
                        cvalue=1;
                        break;
                    case 'refuse':
                        cvalue=0;
                        break;
                    case 'validate':
                        $(this).is(':checked') ? cvalue=1 : cvalue=0;
                        break;
                    default:
                        $(this).is(':checked') ? cvalue=1 : cvalue=0;
                }
                setCookie(cookie_name, cvalue);
                consent.push(cookie_name + "=" + cvalue);
            });

            $.ajax({
                url: "/rgpd-consentement" ,
                data: { consent: consent, token: _token.text()},
                method: "POST",
                success: function(result){ /*console.log(result);*/ }, failed: function(result){ /*console.log(result);*/ }
            });
        }
        else{
            var ip;
            switch (action) {
                case 'accept':
                    ip = '1';
                    break;
                case 'refuse':
                    ip = '0';
                    break;
                case 'validate':
                    ip = '0';
                    break;
                default:

            }
            $.ajax({
                url: "/rgpd-consentement" ,
                data: { consent: "noCookies;ip=" + ip, token: _token.text()},
                method: "POST",
                success: function(result){ /*console.log(result);*/ }, failed: function(result){ /*console.log(result);*/ }
            });
        }
        setCookie('rgpdV2', 1);
    }

    function setCookie(cname, cvalue) {
        var d = new Date(new Date().getTime() + (validity*86400000)).toUTCString();
        var expires = "expires="+ d;
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') { c = c.substring(1); }
            if (c.indexOf(name) == 0) { return c.substring(name.length, c.length); }
        }
        return null;
    }
    // RGPD <<<


    $(".fancybox").fancybox();

    function pushFixSlider() {
        if ($("#header-push-fix").length > 0)
        {
            $("#header-push-fix .slick-wrapper").slick({
                infinite: false,
                slidesToShow: 1,
                draggable: false,
                vertical: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000,
            });
        }
    }

    function bannerSlider() {
        $(".oph-slider.has-slider").slick({
            infinite: false,
            slidesToShow: 1,
            draggable: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 1000,
            responsive: [{

                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }

            }, {

                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }

            }]
        });
    }

    bannerSlider();
    pushFixSlider();

    $(window).scroll(function(event) {
        var height_scroll = $(this).scrollTop();
        if(height_scroll > 100) {
            $('#header-push-fix').addClass('hide_header');
        }
        else {
            $('#header-push-fix').removeClass('hide_header');
        }
    });


}());

jQuery(function ($) {

    // var userLang = navigator.language || navigator.userLanguage; 
    // if(userLang === "fr-FR" || userLang === "fr_FR"){
    jQuery.extend(jQuery.validator.messages, {
        accept: "Veuillez sélectionner un fichier PDF, JPEG ou PNG"
    });
    // }

    // ===== Show/Hide password - Create ======
    $('#tooglePassword_old').on('click', function(){
        var _this = $('#update_password input[id=update_password_oldPassword]').attr('type');
        if (_this === "password") {
            $('#update_password input[id=update_password_oldPassword]').attr("type","text");
            $('#tooglePassword_old').removeClass('fas fa-eye');
            $('#tooglePassword_old').addClass('fas fa-eye-slash');
        } else {
            $('#update_password input[id=update_password_oldPassword]').attr("type","password");
            $('#tooglePassword_old').removeClass('fas fa-eye-slash');
            $('#tooglePassword_old').addClass('fas fa-eye');
        }
    })

    $('#tooglePassword_new').on('click', function(){
        var _this = $('#update_password input[id=update_password_newPassword_first]').attr('type');
        if (_this === "password") {
            $('#update_password input[id=update_password_newPassword_first]').attr("type","text");
            $('#tooglePassword_new').removeClass('fas fa-eye');
            $('#tooglePassword_new').addClass('fas fa-eye-slash');
        } else {
            $('#update_password input[id=update_password_newPassword_first]').attr("type","password");
            $('#tooglePassword_new').removeClass('fas fa-eye-slash');
            $('#tooglePassword_new').addClass('fas fa-eye');
        }
    })

    $('#tooglePassword_confirm').on('click', function(){
        var _this = $('#update_password input[id=update_password_newPassword_second]').attr('type');
        if (_this === "password") {
            $('#update_password input[id=update_password_newPassword_second]').attr("type","text");
            $('#tooglePassword_confirm').removeClass('fas fa-eye');
            $('#tooglePassword_confirm').addClass('fas fa-eye-slash');
        } else {
            $('#update_password input[id=update_password_newPassword_second]').attr("type","password");
            $('#tooglePassword_confirm').removeClass('fas fa-eye-slash');
            $('#tooglePassword_confirm').addClass('fas fa-eye');
        }
    })

    // ===== Show/Hide password - Réinitialiser ======
    $('#tooglePassword_new_pass').on('click', function(){
        var _this = $('.update-password-form input[name=update_password_input]').attr('type');
        if (_this === "password") {
            $('.update-password-form input[name=update_password_input]').attr("type","text");
            $('#tooglePassword_new_pass').removeClass('fas fa-eye');
            $('#tooglePassword_new_pass').addClass('fas fa-eye-slash');
        } else {
            $('.update-password-form input[name=update_password_input]').attr("type","password");
            $('#tooglePassword_new_pass').removeClass('fas fa-eye-slash');
            $('#tooglePassword_new_pass').addClass('fas fa-eye');
        }
    })

    $('#tooglePassword_confirm_pass').on('click', function(){
        var _this = $('.update-password-form input[name=confirm_update_password_input]').attr('type');
        if (_this === "password") {
            $('.update-password-form input[name=confirm_update_password_input]').attr("type","text");
            $('#tooglePassword_confirm_pass').removeClass('fas fa-eye');
            $('#tooglePassword_confirm_pass').addClass('fas fa-eye-slash');
        } else {
            $('.update-password-form input[name=confirm_update_password_input]').attr("type","password");
            $('#tooglePassword_confirm_pass').removeClass('fas fa-eye-slash');
            $('#tooglePassword_confirm_pass').addClass('fas fa-eye');
        }
    })

    // ===== Show/Hide password - Connexion - Locataire, Demandeur & Prestataire ======
    $('#tooglePassword_show_pass').on('click', function(){
        var _this = $('#select-profil-form input[name=votre_mot_de_passe]').attr('type');
        if (_this === "password") {
            $('#select-profil-form input[name=votre_mot_de_passe]').attr("type","text");
            $('#select-profil-form input[name=votre_mot_de_passe]').css("font-family","inherit");
            $('#tooglePassword_show_pass').removeClass('fas fa-eye');
            $('#tooglePassword_show_pass').addClass('fas fa-eye-slash');
        } else {
            $('#select-profil-form input[name=votre_mot_de_passe]').attr("type","password");
            $('#tooglePassword_show_pass').removeClass('fas fa-eye-slash');
            $('#tooglePassword_show_pass').addClass('fas fa-eye');
        }
    })

    // ===== Show/Hide password - Renew ======
    $('#tooglePassword_renew').on('click', function(){
        var _this = $('form[name=renew_password] input#renew_password_newPassword_first').attr('type');
        if (_this === "password") {
            $('.custom-form[name=renew_password] input#renew_password_newPassword_first').attr("type","text");
            $('#tooglePassword_renew').removeClass('fas fa-eye');
            $('#tooglePassword_renew').addClass('fas fa-eye-slash');
        } else {
            $('.custom-form[name=renew_password] input#renew_password_newPassword_first').attr("type","password");
            $('#tooglePassword_renew').removeClass('fas fa-eye-slash');
            $('#tooglePassword_renew').addClass('fas fa-eye');
        }
    })

    $('#tooglePassword_renew_confirm').on('click', function(){
        var _this = $('form[name=renew_password] input#renew_password_newPassword_second').attr('type');
        if (_this === "password") {
            $('.custom-form[name=renew_password] input#renew_password_newPassword_second').attr("type","text");
            $('#tooglePassword_renew_confirm').removeClass('fas fa-eye');
            $('#tooglePassword_renew_confirm').addClass('fas fa-eye-slash');
        } else {
            $('.custom-form[name=renew_password] input#renew_password_newPassword_second').attr("type","password");
            $('#tooglePassword_renew_confirm').removeClass('fas fa-eye-slash');
            $('#tooglePassword_renew_confirm').addClass('fas fa-eye');
        }
    })

})
